.StripeElement {
  box-sizing: border-box;
  height: 55px;
  padding: 10px 12px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #fefefe;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
